import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table , ToggleButtonGroup, ToggleButton, Row, Col} from 'react-bootstrap';
import { GET_ALL_SUBMISSIONS } from '../../graphql/queries';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import LoadingIcon from '../layout/LoadingIcon';

const AllSubmissions = () => {
  const [submittedFilter, setSubmittedFilter] = useState(false);
  // const [statusFilter, setStatusFilter] = useState(null);

  const { loading, error, data } = useQuery(GET_ALL_SUBMISSIONS, {
      variables: { submitted: submittedFilter, status: null },
  });
  
  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;

  return (
    <>
    <Row className="mb-4 bg-light p-4 rounded">
      <Col md={6}>
        <h2>Admin All Submissions Page</h2>
      </Col>
      {/* <Col md={3}>
        <h5>Status</h5>
        <ToggleButtonGroup
          type="radio"
          name="statusOptions"
          defaultValue={1}
          onChange={(val) => {
            if(val === 1) setStatusFilter(true);
            else if(val === 2) setStatusFilter(false);
            else setStatusFilter(null);
          }}
        >
          <ToggleButton id="tbg-radio-status-1" value={1}>
            Ready
          </ToggleButton>
          <ToggleButton id="tbg-radio-status-2" value={2}>
            Need Updates
          </ToggleButton>
          <ToggleButton id="tbg-radio-status-3" value={3}>
            All
          </ToggleButton>
        </ToggleButtonGroup>
      </Col> */}
      <Col md={6}>
        {/* <h5>Accepted or awaiting confirmation</h5> */}
        <ToggleButtonGroup
          type="radio"
          name="submittedOptions"
          defaultValue={2}
          onChange={(val) => {
            if(val === 1) setSubmittedFilter(true);
            else if(val === 2) setSubmittedFilter(false);
            else setSubmittedFilter(null);
          }}
        >
          <ToggleButton id="tbg-radio-submitted-1" value={1}>
            Accepted
          </ToggleButton>
          <ToggleButton id="tbg-radio-submitted-2" value={2}>
            Pending
          </ToggleButton>
          <ToggleButton id="tbg-radio-submitted-3" value={3}>
            All
          </ToggleButton>
        </ToggleButtonGroup>
      </Col>
    </Row>

  
    <Table responsive="sm" striped bordered hover variant="light">
      <thead>
        <tr>
          <th>Title</th>
          <th>Severity</th>
          <th>Comment</th>
          <th>Status</th>
          <th>Accepted?</th>
          <th>By</th>
          <th>Submit Date</th>
          <th>See Details</th>
        </tr>
      </thead>
      <tbody>
        {data.allSubmissions.map(({ id, title, severity, comment, status, submitted, by,submitDate }) => (
          <tr key={id}>
            <td>{title}</td>
            <td>{severity}</td>
            <td>{comment}</td>
            <td>{status ? 'Ready' : 'Not Ready'}</td>
            <td>{submitted ? 'Yes' : 'No'}</td>
            <td>{by}</td>
            <td>{new Date(submitDate).toLocaleDateString()}</td>
            <td>
            <Link to={`/submission/${id}`}>
              <Button variant="primary">Details</Button>
            </Link>
              </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );
};

export default AllSubmissions;