import jwt_decode from "jwt-decode";
import { useForm } from 'react-hook-form';
import { useAuth } from '../../AuthContext';

import React, {useEffect}  from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_2FA } from '../../graphql/mutations';
import { useRedirect } from '../../RedirectContext';


function Verify2FAPage() {

    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const [verify2FA, { error }] = useMutation(VERIFY_2FA);
    const { name, setUser, token, setToken } = useAuth();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    const { redirectPath } = useRedirect();

    if (!token) {
        return null;
    }
  
    const onSubmit = async (values) => {
      try {
        const response = await verify2FA({ variables: values });
  
        if (response.data.verify2fa.success) {
            // handle success
            const token = response.data.verify2fa.token;
            const decoded = jwt_decode(token);
            setUser(decoded);
            setToken(token);
            localStorage.setItem('auth-token', token);
            navigate(redirectPath && redirectPath !== '/' ? redirectPath : '/dashboard');
        } else {
          // handle failure
          console.error("2FA Verification failed: ", response.data.verify2fa.message);
        }
      } catch (err) {
        console.error(err);
      }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <span className="mt-6 text-center leading-9 tracking-tight text-gray-900">
                <p>🔐 <strong>Redirecting to 2FA Verification...</strong></p>
                <br/>
                <div>
                    <p>Welcome back, {name}!</p>
                    <p>Please have your authentication device ready.</p>
                </div>
                </span>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-7 shadow sm:rounded-lg sm:px-12">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label htmlFor="totp-code" className="block text-sm font-medium leading-6 text-gray-900">
                                Enter the TOTP from authenticator
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="totp-code"
                                    id="totp-code"
                                    {...register("totp")}
                                    maxLength="6"
                                    required
                                    placeholder="000-000"
                                    className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 text-center placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Verify
                            </button>
                        </div>
                        <p>Need assistance? please contact our support team at support@bugswagger.com</p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Verify2FAPage;
