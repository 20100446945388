import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, DocumentTextIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import { MY_PROGRAMS } from '../../graphql/queries';

const statuses = {
  "In progress": 'text-blue-500 bg-blue-50 ring-blue-600/20',
  "Completed": 'text-green-700 bg-green-50 ring-green-600/20',
  "Not started": 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
  "Hot": 'text-red-700 bg-red-50 ring-red-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function MyPrograms() {

  const { loading, error, data } = useQuery(MY_PROGRAMS);

  const myPrograms = data?.myPrograms;

  if (!myPrograms || myPrograms.length < 1) return <>Enjoy this brief respite - it's well-earned downtime before your next challenge!</>;

  return (
    <div className='bg-white px-4 py-12 sm:px-6 lg:px-8'>
    <div className='mx-auto max-w-4xl'>
    <ul role="list" className="divide-y divide-gray-100">
      {myPrograms?.map((pentest) => (
        <li key={pentest.Id} className="flex items-center justify-between gap-x-6 py-5">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{pentest.name}</p>
              <p
                className={classNames(
                  statuses[pentest.name],
                  'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                )}
              >
                {pentest.name}
              </p>
              {pentest.numFinding !== 0 && (
                <div className="flex w-16 gap-x-2.5">
                  <dt>
                    <span className="sr-only">Total reports</span>
                      <DocumentTextIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-900">{pentest.numFinding}</dd>
                </div>
              )}
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="truncate">{pentest.website}</p>
            </div>
          </div>
          <div className="flex flex-none justify-between gap-x-8 sm:w-auto">
            <div className="flex space-x-1.5">
            {/* <dt className="sr-only">Pentesters</dt> */}
            {pentest?.recipients?.map((pentester) => (
              <p
                // href={pentest.href}
                className='rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset bg-white-50 ring-gray-600/20'
              >
                {pentester}<span className="sr-only">, {pentester}</span>
              </p>
            ))}
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            {/* <span className="ml-3 hidden sm:block"> */}
              <Link
                to={pentest.Id}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View pentest<span className="sr-only">, {pentest.name}</span>
              </Link>
            {/* </span> */}
            <Menu as="div" className="relative flex-none">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        Completed<span className="sr-only">, {pentest.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        In progress<span className="sr-only">, {pentest.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </li>
      ))}
    </ul>
    </div>
    </div>
  )
};

export default MyPrograms;