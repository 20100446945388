import { CheckIcon, HandThumbUpIcon, UserIcon, TrashIcon, ExclamationTriangleIcon} from '@heroicons/react/20/solid'

const timeline = [
  {
    id: 1,
    content: 'Created new user to {user}',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: UserIcon,
    iconBackground: 'bg-gray-400',
    by: 'Badr',
  },
  {
    id: 2,
    content: 'Marked {company} as done',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    icon: HandThumbUpIcon,
    iconBackground: 'bg-blue-500',
    by: 'Badr',
  },
  {
    id: 3,
    content: 'Submited report to {company}',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
    by: 'Badr',
  },
  {
    id: 4,
    content: 'Skip {company} reason hard',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    icon: ExclamationTriangleIcon,
    iconBackground: 'bg-yellow-500',
    by: 'Badr',
  },
  {
    id: 5,
    content: 'Remove {user} profile',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    icon: TrashIcon,
    iconBackground: 'bg-gray-400',
    by: 'Badr',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Feeds() {
  return (
    <div className="bg-white py-12">
    <div className="mx-auto max-w-lg px-6">
    <div className="flow-root">
      <ul className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                  {/* class="text-sm text-gray-500" */}
                  {/* <span className="font-medium text-gray-500">{event.by}</span> */}
                </div>
                {/* <p className="flex-auto text-gray-500">
                </p> */}
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>

                    <p className="text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {event.by}{' '}
                      </span>
                      {event.content}
                    </p>

                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    </div>
    </div>
  )
}
