import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

import { useAuth } from '../../AuthContext';

import { useQuery, useMutation } from '@apollo/client';
import { GET_SUBMISSION } from '../../graphql/queries';
import { UPDATE_SUBMISSION, DELETE_SUBMISSION } from '../../graphql/mutations';
import LoadingIcon from '../layout/LoadingIcon';

const SubmissionDetails = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState('');
  const [description, setDescription] = useState('');
  const [impact, setImpact] = useState('');

  const { loading, error, data } = useQuery(GET_SUBMISSION, {
    variables: { id },
  });

  const [updateSubmission] = useMutation(UPDATE_SUBMISSION);
  const [deleteSubmission] = useMutation(DELETE_SUBMISSION);

  useEffect(() => {
    if (data) {
      const submission = data?.submission;
      if (submission) {
        setTitle(data.submission.title);
        setSeverity(data.submission.severity);
        setDescription(data.submission.description);
        setImpact(data.submission.impact);
      }
    }
  }, [data]);
  
  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;

  const submission = data?.submission;

  if (!submission) {
    return (
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Card className="text-center" border="light">
              <Card.Body>
                <Card.Title>No Submission Found</Card.Title>
                <Card.Text>
                  We're sorry, but the submission you're looking for doesn't seem to exist. 
                  It may have been removed, or it may not have been created yet. 
                  Please check the submission ID and try again.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  // Update
  const submitUpdate = (acceptedStatus) => {
    updateSubmission({ variables: { id, title, severity, description, impact, accepted: acceptedStatus} });
    setIsEditing(false);
    if (acceptedStatus)
      navigate("/all-submissions");
  };

  // Delete
  const submitDelete = () => {
    deleteSubmission({ variables: { id } });
    navigate("/my-submissions");
  };
  

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const submitDate = submission.submitDate;
  const date = new Date(submitDate);
  
  date.setHours(date.getHours() + 3);

  const dateString = date.toLocaleDateString(undefined, options);
  const timeString = date.toLocaleTimeString();

  const dateTimeString = `${dateString} ${timeString}`;

  const markdownContent = `
${description}

**Impact**

${impact}
`;

  return (
    <Container className="mt-5">
      <Row className="mb-4 bg-light p-4 rounded">
        <Row>
        <Col md={7}>
          {isEditing ? (
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <h4>{title}</h4>
          )}
        </Col>
        <Col md={2}>
          {isEditing ? (
            <Form.Select
              aria-label="Severity Select"
              value={severity}
              onChange={(e) => setSeverity(e.target.value)}
            >
              <option>Select Severity</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
              <option value="critical">Critical</option>
            </Form.Select>
          ) : (
            <p>
            <strong>Severity:</strong> {severity}
          </p>
          )}
        </Col>
        { !submission.submitted && (
          <Col md={3} style={{direction: 'rtl'}}>
            {isEditing ? (
              <Row>
                <Col xs={6}>
                  <Button variant="success" onClick={() => submitUpdate(false)}>Confirm</Button>
                </Col>
                <Col xs={6}>
                  <Button variant="danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                </Col>
              </Row>
              ) : (
              <Row>
                <Col xs={6}>
                  <Button variant="primary" onClick={() => setIsEditing(true)}>Edit Report</Button>
                </Col>
                { user && user.isSuperUser && (
                <Col xs={6}>
                  <Button variant="success" onClick={() => submitUpdate(true)}>Accept</Button>
                </Col>
                )}
              </Row>
              )}
          </Col>
        )}
        </Row>
        <Row>
        <Col md={7}>
          <p>
            <strong>Submit Date:</strong> {dateTimeString}
          </p>
        </Col>
        <Col md={2}>
          { user && user.isSuperUser && (
          <p>
            <strong>Submitted by:</strong> {submission.by}
          </p>
          )}
        </Col>
        {/* <Col md={3}>
          
        </Col> */}
        </Row>
      </Row>
      <Card className="p-4">
        {isEditing ? (
          <Form>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={14}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="impact">
              <Form.Label>Impact</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                value={impact}
                onChange={(e) => setImpact(e.target.value)}
              />
            </Form.Group>
          </Form>
        ) : (
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        )}
      </Card>
      <Row className="mt-4" style={{padding: '0', marginRight: '0px'}}>
        {(!submission.submitted || (user && user.isSuperUser)) && (
        <Button variant="danger" onClick={submitDelete}>Delete Report</Button>
        )}
      </Row>
    </Container>
  );
};

export default SubmissionDetails;