import { createContext, useContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('auth-token');
    if (storedToken) {
      try {
        const decoded = jwt_decode(storedToken);
        const expirationTime = decoded.exp * 1000;
        if (expirationTime >= Date.now()) {
          setToken(storedToken);
          setName(decoded.nickname)
          if (decoded.totp_verified) setUser(decoded);
        } else {
          localStorage.removeItem('auth-token');
        }
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }
  }, []);

  const value = {
    token,
    setToken,
    user,
    setUser,
    name,
    setName
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}