import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Table from 'react-bootstrap/Table';
import { GET_MY_SUBMISSIONS } from '../../graphql/queries';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { ToggleButtonGroup, ToggleButton, Col, Row } from 'react-bootstrap';
import LoadingIcon from '../layout/LoadingIcon';


const MySubmissions = () => {

  const [filter, setFilter] = useState('Accepted');
  const { loading, error, data, refetch } = useQuery(GET_MY_SUBMISSIONS, {
      variables: { status: filter },
  });

  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;
  
  return (
    <>
      <Row style={{textAlign : 'center', padding: '20px 0'}}>
        <Col>
          <h4>My Submissions</h4>
        </Col>
        <Col>
          <ToggleButtonGroup
            type="radio"
            name="options"
            defaultValue={1}
            onChange={(val) => {
              if(val === 1) setFilter('Accepted');
              else if(val === 2) setFilter('Pending');
              else setFilter('All');
            }}
          >
            <ToggleButton id="tbg-radio-1" value={1}>
              Accepted
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={2}>
              Pending
            </ToggleButton>
            <ToggleButton id="tbg-radio-3" value={3}>
              All
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Table responsive="md" bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Severity</th>
            <th>Accepted?</th>
            <th>Submitted Date</th>
            <th>See Details</th>
          </tr>
        </thead>
        <tbody>
          {data.mySubmissions.map(({ id, title, severity, submitted, submitDate }) => (
            <tr key={id}>
              <td>{title}</td>
              <td>{severity}</td>
              <td>{submitted ? 'Yes' : 'No'}</td>
              <td>{new Date(submitDate).toLocaleDateString()}</td>
              <td>
              <Link to={`/submission/${id}`}>
                <Button variant="primary">Details</Button>
              </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
    );
  };
  
  export default MySubmissions;