import { createContext, useContext, useState } from 'react';

const RedirectContext = createContext();

export function RedirectProvider({ children }) {
  const [redirectPath, setRedirectPath] = useState('/');

  return (
    <RedirectContext.Provider value={{ redirectPath, setRedirectPath }}>
      {children}
    </RedirectContext.Provider>
  );
}

export function useRedirect() {
  return useContext(RedirectContext);
}