import React, { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useAuth } from './AuthContext';
import { useRedirect } from './RedirectContext';

function ProtectedRoute() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setRedirectPath } = useRedirect();

  const isAuthenticated = () => {
    if (!token) return false;
    try {
      const decoded = jwt_decode(token);
      return (decoded.exp >= Date.now() / 1000) && decoded.totp_verified;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      if (token) {
        navigate('/verify-2fa');
      } else {
        setRedirectPath(location.pathname);
        navigate('/login');
      }
    }
  }, [isAuthenticated, navigate, location, setRedirectPath, token]);

  return <Outlet />;
}

export default ProtectedRoute;
