import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import { EllipsisVerticalIcon, DocumentTextIcon, FunnelIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import { useQuery, useMutation } from '@apollo/client';
import { ALL_PROGRAMS } from '../../graphql/queries';
import { UPDATE_PROGRAM } from '../../graphql/mutations';
import LoadingIcon from '../layout/LoadingIcon';

const statuses = {
    Pentest: 'text-blue-700 bg-blue-50 ring-blue-600/20',
    Bounty: 'text-green-700 bg-green-50 ring-green-600/20',
    "Public Program": 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Clients() {

  const { loading, error, data } = useQuery(ALL_PROGRAMS);
  const [updateProgram, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_PROGRAM);

  const [filterOption, setFilterOption] = useState('Latest');
  const [filteredClients, setFilteredClients] = useState([]);
  const [numFiltered, setNumFiltered] = useState(filteredClients.length);

  useEffect(() => {
    if (data && data.programs) {
      let filteredClients;
      if (filterOption === 'Latest') {
        filteredClients = [...data.programs].sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
      } else {
        filteredClients = data.programs.filter(program => program.dealType === filterOption);
      }
      setFilteredClients(filteredClients);
      setNumFiltered(filteredClients.length);
    }
  }, [data, filterOption]);

  // const handleChangeStatus = async (values) => {
  //   try {
  //     await updateProgram({
  //       variables: {
  //         id: values.id,
  //         dealStatus: values.dealStatus
  //       },
  //     });
  //   } catch (error) {
  //     if (error.networkError && error.networkError.result) {
  //       console.error('Error details:', error.networkError.result.errors);
  //     }
  //   }
  // };
  
  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;

  return (
    <div className='bg-white px-4 py-12 sm:px-6 lg:px-8'>
    <div className='mx-auto max-w-4xl'>
      
      {/* filter */}

      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <div className="flex-shrink-0">
              <Menu as="div" className="relative">
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <FunnelIcon className="-ml-0.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                  {numFiltered} {filterOption}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Latest')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Latest
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Pentest')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Pentest
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Bounty')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Bounty
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Public Program')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Public Program
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add new client
            </button>
          </div>
        </div>
      </div>

      {/* programs list */}
      <ul role="list" className="divide-y divide-gray-100">
        {filteredClients.map((program) => (
          <li key={program.Id} className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{program.name}</p>
                <p
                  className={classNames(
                    statuses[program.dealType],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {program.dealType}
                </p>
                {program.numFindings !== 0 && (
                  <div className="flex w-16 gap-x-2.5">
                    <dt>
                      <span className="sr-only">Total reports</span>
                        <DocumentTextIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-900">{program.numFindings}</dd>
                  </div>
                )}
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Due on <time dateTime={program.addedDate}>{program.addedDate}</time>
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">{program.website}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                {/* <p className="truncate">Created by {program.createdBy}</p> */}
              </div>
            </div>
            <div className="flex flex-none justify-between gap-x-8 sm:w-auto">
              <div className="flex space-x-1.5">
              {/* <dt className="sr-only">Pentesters</dt> */}
              {program.recipients?.map((pentester) => (
                <p
                  // href={program.href}
                  className='rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset bg-white-50 ring-gray-600/20'
                >
                  {pentester}<span className="sr-only">, {pentester}</span>
                </p>
              ))}
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <Link
                to={program.Id}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View program<span className="sr-only">, {program.name}</span>
              </Link>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Share<span className="sr-only">, {program.name}</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={() => updateProgram({
                            variables: { id: program.Id, dealStatus: program.dealStatus ? 0 : 1}
                          })}
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          {program.dealStatus ? "Archive" : "Published"} <span className="sr-only">, {program.name}</span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>

    </div>
    </div>
  )
};

export default Clients;