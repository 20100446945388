import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ENABLE_2FA, DISABLE_2FA } from '../../graphql/mutations';
import QRCode from 'qrcode.react';
import LoadingIcon from '../layout/LoadingIcon';


const Settings = () => {
  const [enable2FA, { data: enableData, loading: enableLoading, error: enableError }] = useMutation(ENABLE_2FA);
  const [disable2FA, { data: disableData, loading: disableLoading, error: disableError }] = useMutation(DISABLE_2FA);
  const [showQR, setQR] = useState(false);

  const enable2FAHandler = async () => {
    try {
      await enable2FA();
      setQR(true);
    } catch (err) {
      console.error(err);
    }
  };

  const disable2FAHandler = async () => {
    try {
      await disable2FA();
      setQR(false);
    } catch (err) {
      console.error(err);
    }
  };

  if (enableLoading || disableLoading) return <LoadingIcon />;
  if (enableError || disableError) return <p>Error :(</p>;

  return (
    <div>
      <button onClick={enable2FAHandler}>Enable 2FA</button>
      <button onClick={disable2FAHandler}>Disable 2FA</button>
      {showQR && enableData?.enable2fa.success &&
        <div>
          <p>Scan this QR code with your authenticator:</p>
          <QRCode value={enableData?.enable2fa.qrCodeUri} />
        </div>
      }
    </div>
  );
};

export default Settings;
