import ProtectedRoute from './ProtectedRoute';

import { AuthProvider, useAuth } from './AuthContext';

import { RedirectProvider } from './RedirectContext';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';


// Auth
import Login from './components/auth/Login';
import CreateUser from './components/auth/CreateUser';
import Settings from './components/auth/Settings';
import Verify2FAPage from './components/auth/Verify2fa';

// layout
import Banner from './components/layout/Banner';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// submissions
import SubmitReport from './components/submissions/SubmitReport';
import MySubmissions from './components/submissions/MySubmissions';
import AllSubmissions from './components/submissions/AllSubmissions';
import SubmissionDetails from './components/submissions/SubmissionDetails';

// pages
import Dashboard from './components/pages/Dashboard';
import Feeds from './components/pages/Feeds';
import Clients from './components/pages/Clients';
import Program from './components/pages/Program';
import Mails from './components/pages/Mails';
import MyPrograms from './components/pages/MyPrograms';


const navigation = [
  { headerTitle: 'Dashboard', href: '/' },
  { headerTitle: 'Sign in', href: '/login' },
  { headerTitle: '2FA', href: '/verify-2fa' },
  { headerTitle: 'Settings', href: '/settings'},
  // { headerTitle: 'Dashboard', href: '/dashboard' },
  { headerTitle: 'BugSwagger penetration testing contracts', href: '/pentests' },
  // { headerTitle: 'BugSwagger Clients', href: '/clients' },
  { headerTitle: 'Submit Report', href: '/submit-report' },
  { headerTitle: 'My Submissions', href: '/my-submissions' },
  { headerTitle: 'All Submissions', href: '/all-submissions' },
  // { headerTitle: 'Mails', href: '/mails' },
  // { headerTitle: 'Automate Domains', href: '/add-list' },
];


function App() {

  const location = useLocation();

  const { user } = useAuth();
  const currentPath = location.pathname;

  const currentItem = navigation.find(item => item.href === currentPath);
  
  return (
    <div className="min-h-full">
      
      {user && <Banner/> }

      <Navbar/>

      {user && currentItem &&
      <header className="bg-white shadow-sm">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            {currentItem.headerTitle}
          </h1>
        </div>
      </header>
      }

      <main className='bg-white'>
        <Routes>

          <Route path="/add-hacker" element={<ProtectedRoute />}>
            <Route index element={<CreateUser />} />
          </Route>

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          
          {/* Protected Routes */}

          {/* <Route path="/add-list" element={<ProtectedRoute />}>
            <Route index element={<List />} />
          </Route> */}
          
          <Route path="/my-submissions" element={<ProtectedRoute />}>
            <Route index element={<MySubmissions />} />
          </Route>
          <Route path="/submission/:id" element={<ProtectedRoute />}>
            <Route index element={<SubmissionDetails />} />
          </Route>
          
          <Route path="/all-submissions" element={<ProtectedRoute />}>
            <Route index element={<AllSubmissions />} />
          </Route>

          <Route path="/mails" element={<ProtectedRoute />}>
            <Route index element={<Mails />} />
          </Route>
          
          {/* <Route path="/validate" element={<ProtectedRoute />}>
            <Route index element={<Validate />} />
          </Route>
          
          <Route path="/reports" element={<ProtectedRoute />}>
            <Route index element={<RandomReport />} />
          </Route> */}
          
          <Route path="/submit-report" element={<ProtectedRoute />}>
            <Route index element={<SubmitReport />} />
          </Route>

          <Route path="/dashboard" element={<ProtectedRoute />}>
            <Route index element={<Dashboard />} />
          </Route>

          <Route path="/feeds" element={<ProtectedRoute />}>
            <Route index element={<Feeds />} />
          </Route>

          <Route path="/clients" element={<ProtectedRoute />}>
            <Route index element={<Clients />} />
          </Route>
          
          <Route path="/pentests" element={<ProtectedRoute />}>
            <Route index element={<MyPrograms />} />
          </Route>

          <Route path="/clients/:id" element={<ProtectedRoute />}>
            <Route index element={<Program />} />
          </Route>

          <Route path="/pentests/:id" element={<ProtectedRoute />}>
            <Route index element={<Program />} />
          </Route>

          <Route path="/settings" element={<ProtectedRoute />}>
            <Route index element={<Settings />} />
          </Route>

          <Route path="/verify-2fa">
            <Route index element={<Verify2FAPage />} />
          </Route>

        </Routes>
      </main>
      <Footer/>
    </div>
  );
}

export default function AuthenticatedApp() {
  return (
    <AuthProvider>
      <RedirectProvider>
        <Router>
          <App />
        </Router>
      </RedirectProvider>
    </AuthProvider>
  );
}