import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Form, Button,Row, Col, Tab, Nav, Spinner
} from 'react-bootstrap';

import ReactMarkdown from 'react-markdown';

import { useMutation } from '@apollo/client';
import { SUBMIT_REPORT_MUTATION } from '../../graphql/mutations';


function SubmitReport() {  
  const location = useLocation();
  const navigate = useNavigate();
  const reportData = location.state?.reportData;

  const [submitReport, { loading, error, data }] = useMutation(SUBMIT_REPORT_MUTATION);

  const defaultMessage = `**Summary:**

**Description:**

***Steps To Reproduce***

1. 
2. 
3. `
  const [description, setDescription] = useState(defaultMessage);
  const [formValid, setFormValid] = useState(false);

  const handleFormChange = (e) => {
    // Check if the required fields are filled
    const form = e.target.form;
    const title = form.elements.title.value;
    const description = form.elements.description.value;
    const impact = form.elements.impact.value;

    // Set formValid state based on the fields' values
    setFormValid(title && description && impact);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const getSubmitButtonStyle = () => {
    return formValid
      ? { background: '#0000', borderColor: '#e10e71', color: '#e10e71' }
      : { background: '#0000', borderColor: '#9e9e9e', color: '#3e3e3e' };
  };

  const getDraftButtonStyle = () => {
    return formValid
      ? { background: '#e10e71', borderColor: '#e10e71', color: '#fff' }
      : { background: '#e9e9e9', borderColor: '#f7f8f9', color: '#9e9e9e' };
  };

  const { register, handleSubmit } = useForm();

  const onSubmit = async (values) => {
      
    if (values.status === 'ready') {
      values.status = true;
    } else {
      values.status = false;
    }
    // Add the reportData.id to the values object
    values.domainId = reportData.id;
    
    try {
      const { data } = await submitReport({ variables: { ...values } });

      if (data.submitReport.success) {
        console.log('Report submitted successfully.');
      } else {
        console.error('An error occurred while submitting the report.');
        // handle error, e.g., show error message
      }
    } catch (error) {
      console.error('GraphQL error:', error);
      // handle GraphQL error, e.g., show error message
    }
  };

  return (
    <>
      <h4 className="text-center mb-4">Submit Report To {reportData.domain}</h4>
      <Form
        onChange={handleFormChange}
        onSubmit={handleSubmit(onSubmit)}
        className="submit-report-form">
        
        <Row>
          <Col md={10}>

            <Form.Group controlId="title" className="form-input">
              <Form.Label className="form-label">
                <strong>Title</strong><span className="required">*</span>
              </Form.Label>
              <Form.Text className="text-muted">
                A clear and concise title includes the type of vulnerability and the impacted asset.
              </Form.Text>
              <Form.Control {...register("title", { required: true })} type="text" placeholder="Title" />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="severity" className="form-input" style={{paddingTop: '22px'}}>
              <Form.Label className="form-label">
                <strong>Severity</strong><span className="required">*</span>
              </Form.Label>
              <Form.Control {...register("severity", { required: true })} as="select">
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="critical">Critical</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="description" className="form-input">
        <Form.Label className="form-label">
          <strong>Description</strong><span className="required">*</span>
        </Form.Label>
        <Form.Text className="text-muted">
          What is the vulnerability? In clear steps, how do you reproduce it?
        </Form.Text>
        <Tab.Container id="description-tabs" defaultActiveKey="write">
          <Row>
            <Col sm={12}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="write">Write</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="preview">Preview</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
              <Tab.Pane eventKey="write">
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    as="textarea"
                    {...register("description", { required: true })}
                    rows={18}
                    onChange={handleDescriptionChange}
                    defaultValue={defaultMessage}
                    style={{ paddingRight: '80px' }}
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="preview">
                <Form.Group controlId="preview" className="form-input">
                  <div className="markdown-preview">
                    <ReactMarkdown>{description}</ReactMarkdown>
                  </div>
                </Form.Group>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Form.Group>

      <Form.Group controlId="impact">
        <Form.Label className="form-label">
          <strong>Impact</strong><span className="required">*</span>
        </Form.Label>
        <Form.Text className="text-muted">What security impact can an attacker achieve?</Form.Text>
        <Form.Control {...register("impact", { required: true })}
          as="textarea" rows={6}/>
      </Form.Group>

      <Form.Group controlId="status" className="form-input">
        <Form.Label className="form-label">
          <strong>Status</strong><span className="required">*</span>
        </Form.Label>
        <Form.Control {...register("status", { required: true })} as="select">
          <option value="ready">Ready</option>
          <option value="not_ready">Not Ready</option>
        </Form.Control>
      </Form.Group> 

      <Form.Group controlId="comment" className="form-input">
        <Form.Label className="form-label">
          <strong>Comment</strong>
        </Form.Label>
        <Form.Control {...register("comment")} as="textarea" rows={3} placeholder="Comment" />
      </Form.Group>

      <Row className="mt-4">
      <Col>
        <Button id="submit" variant="primary" type="submit"
          disabled={!formValid}
          style={getSubmitButtonStyle()}>
          Create Draft
        </Button>
      </Col>
      <Col>
        <Button id="draft" variant="success" type="submit"
          disabled={!formValid}
          style={getDraftButtonStyle()}>
          Submit Report
        </Button>
        {loading && (
          <div className="loading-spinner">
            <Spinner animation="border" />
          </div>
        )}
      </Col>
    </Row>
    {data && data.submitReport.success ? (
        navigate("/pentests", { state: { key: Date.now(), success: true, msg: "Your report submitted successfully." } })
      ) : error ? (
        navigate("/pentests", { state: { key: Date.now(), success: false, msg: error.message } })
      ) : null
    }
    </Form>
  </>
  );
}
export default SubmitReport;