import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($username: String!, $password: String!, $nickname: String!, $email: String!) {
    createUser(username: $username, password: $password, nickname: $nickname, email: $email) {
      success
      user {
        id
        username
        nickname
        email
      }
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($id: ID!, $saved: Boolean!, $unfulfillingUserData: UnfulfillingUserDataInput!) {
    updateReport(id: $id, saved: $saved, unfulfillingUserData: $unfulfillingUserData) {
      report {
        domain
      }
    }
  }
`;

export const UPDATE_DOMAIN = gql`
  mutation UpdateDomain($id: ID!, $done: Boolean!, $unfulfillingUserData: UnfulfillingUserDataInput!) {
    updateDomain(id: $id, done: $done, unfulfillingUserData: $unfulfillingUserData) {
      success
    }
  }
`;

export const SUBMIT_REPORT_MUTATION = gql`
  mutation SubmitReport(
    $domainId: ID!
    $title: String!
    $severity: String!
    $description: String!
    $impact: String!
    $status: Boolean!
    $comment: String!
  ) {
    submitReport(
      domainId: $domainId
      title: $title
      severity: $severity
      description: $description
      impact: $impact
      status: $status
      comment: $comment
    ) {
      success
    }
  }
`;

export const UPDATE_SUBMISSION = gql`
  mutation UpdateSubmission(
    $id: ID!
    $title: String!
    $severity: String!
    $description: String!
    $impact: String!
    $accepted: Boolean!
  ) {
    updateSubmission(
      id: $id
      title: $title
      severity: $severity
      description: $description
      impact: $impact
      accepted: $accepted
    ) {
      success
    }
  }
`;

export const DELETE_SUBMISSION = gql`
  mutation DeleteSubmission($id: ID!) {
    deleteSubmission(id: $id) {
      success
    }
  }
`;

export const UPDATE_MAIL = gql`
  mutation UpdateMail($id: ID!, $status: String!) {
    updateMail(id: $id, status: $status) {
      success
    }
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: String!, $dealStatus: Int, $recipients: String) {
    updateProgram(id: $id, dealStatus: $dealStatus, recipients: $recipients) {
      success
      dealStatus
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($id: ID!, $comment: String!) {
    addComment(id: $id, comment: $comment) {
      success
      program {
        Id
        comments {
          user
          comment
          addedDate
        }
    }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      success
      token
      message
    }
  }
`;

export const VERIFY_2FA = gql`
  mutation Verify2fa($totp: String!) {
    verify2fa(totp: $totp) {
      success
      token
      message
    }
  }
`;

export const ENABLE_2FA = gql`
  mutation Enable2fa {
    enable2fa {
      success
      qrCodeUri
    }
  }
`;

export const DISABLE_2FA = gql`
  mutation Disable2fa {
    disable2fa {
      success
    }
  }
`;
