import { useAuth } from '../../AuthContext';
import { useLocation, Link } from 'react-router-dom';


import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

let baseNavigation = [
  { name: 'Dashboard', href: '/dashboard' },
  { name: 'Pentests', href: '/pentests' },
  { name: 'My Submissions', href: '/my-submissions' },
];

let superNavigation = [
  { name: 'All Submissions', href: '/all-submissions' },
  { name: 'Mails', href: '/mails' },
  { name: 'Add hacker', href: '/add-hacker' }
];

let superUserExtraNavigation = [
  { name: 'Feeds', href: '/feeds' },
  { name: 'Clients', href: '/clients' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  const { user, setUser, setToken, name } = useAuth();

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('auth-token');
    localStorage.setItem('bannerDismissed', 'false');
  };

  const location = useLocation();

  const currentPath = location.pathname;

  let navigation;

  if (user && user.isSuperUser) {
    navigation = [...superUserExtraNavigation, ...baseNavigation, ...superNavigation];
    navigation.splice(3, 1);
  } else {
    navigation = [...baseNavigation];
  }

  const [key, setKey] = useState(Math.random());

  const handleClick = () => {
    setKey(Math.random()); // change key, which unmounts and re-mounts the component
  };

  return (
    
    <Disclosure key={key} as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src="https://www.bugswagger.com/imgs/logo.png"
                    alt="BugSwagger"
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    { user &&
                      <>
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={ classNames(
                            item.href === currentPath
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.href === currentPath ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </>
                  }
                  {!user && !name &&
                    <Link 
                      to="/login"
                      className={ classNames(
                        '/login' === currentPath
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                      aria-current={'login' === currentPath ? 'page' : undefined}>
                        Sign in
                    </Link>
                  }
                  {!user && name &&
                    <Link
                      to='/login'
                      onClick={logout}
                      className="block px-4 py-2 text-sm text-gray-700">
                        Sign out
                    </Link>
                  }
                  </div>
                </div>
              </div>
              {user && 
              
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        Logged in as {user.nickname}
                        {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({ active }) => (
                              <Link
                                to='/settings'
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}>
                                Settings
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to='/login'
                                onClick={logout}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}>
                                Sign out
                              </Link>
                            )}
                          </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              }
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          
          <Disclosure.Panel className="md:hidden">
            { user &&
            <>
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={handleClick}
                  className={classNames(
                    item.href === currentPath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.href === currentPath ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{user.nickname}</div>
                </div>
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">

              <Link
                  // key="settings"
                  to="/settings"
                  onClick={handleClick}
                  className={classNames(
                    "/settings" === currentPath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={"/settings" === currentPath ? 'page' : undefined}
                >
                  Settings
                </Link>
                
                <Disclosure.Button
                  as="a"
                  onClick={() => { logout(); handleClick(); }}
                  href="/login"
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Sign out
                </Disclosure.Button>

              </div>
            </div>
            </>
            }
          {!user &&
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              <Link as="a" href='/login'
                className={classNames(
                  '/login' === currentPath ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
                aria-current={'/login' === currentPath ? 'page' : undefined}
              >
                Sign in
              </Link>
            </div>
          }
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}