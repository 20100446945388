import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition, Popover } from '@headlessui/react'
import { EllipsisVerticalIcon, FunnelIcon, ChevronDownIcon, EnvelopeIcon, InboxArrowDownIcon, ForwardIcon } from '@heroicons/react/20/solid'

import { useQuery, useMutation } from '@apollo/client';
import { GET_MAILS } from '../../graphql/queries';

import { UPDATE_MAIL } from '../../graphql/mutations';

import axios from 'axios';

import { useAuth } from '../../AuthContext';
import LoadingIcon from '../layout/LoadingIcon';

const statuses = {
  Pentest: 'text-blue-700 bg-blue-50 ring-blue-600/20',
  Bounty: 'text-green-700 bg-green-50 ring-green-600/20',
  "Public Program": 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
  Unknown: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  "No Bounty": 'text-red-800 bg-red-50 ring-red-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Mails() {

  const { token } = useAuth();

  const { loading, error, data } = useQuery(GET_MAILS);
  const [updateMail, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MAIL);

  const [filterOption, setFilterOption] = useState('Latest');
  const [filteredMails, setfilteredMails] = useState([]);
  const [numFiltered, setNumFiltered] = useState(filteredMails.length);

  useEffect(() => {
    if (data && data.mails) {
      let filteredMails;
      if (filterOption === 'Latest') {
        filteredMails = [...data.mails].sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
      } else {
        filteredMails = data.mails.filter(mail => mail.status === filterOption);
      }
      setfilteredMails(filteredMails);
      setNumFiltered(filteredMails.length);
    }
  }, [data, filterOption]);


  const handleChangeStatus = async (values) => {
    try {
      await updateMail({
        variables: {
          id: values.id,
          status: values.status
        },
      });
    } catch (error) {
      if (error.networkError && error.networkError.result) {
        console.error('Error details:', error.networkError.result.errors);
      }
    }
  };

  const fecthNewMessages = async () => {
    try {
      await axios({
        method: 'post',
        url: 'http://localhost:5000/automation/mails',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token, 
        },
      });
    } catch (error) {
        console.error(error);
    }
  };


  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;
  
  
  return (
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <div className="px-4 sm:px-6 lg:px-8">

      {/* filter */}
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <div className='flex flex-shrink-0'>
              <Menu as="div" className="relative">
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <FunnelIcon className="-ml-0.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                  {numFiltered} {filterOption}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Latest')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Latest
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Pentest')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Pentest
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Bounty')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Bounty
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Public Program')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Public Program
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('Unknown')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Unknown
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a href="#"
                          onClick={() => setFilterOption('No Bounty')}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          No Bounty
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </div>
          <div className="ml-4 mt-2 flex flex-shrink-0">
            <button
              type="button"
              onClick={fecthNewMessages}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <InboxArrowDownIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Fetch new messages</span>
            </button>
            <button
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <ForwardIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Forward to clients</span>
            </button>
          </div>
        </div>
      </div>
      
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredMails.map((mail) => (
                  <tr key={mail.Id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">{mail.sender}</div>
                          <div className="mt-1 text-gray-500">{mail.senderEmail}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{mail.subject}</div>
                      <div className="mt-1 text-gray-500">{mail.companyWebsite}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span
                        className={classNames(
                          statuses[mail.status],
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                      >
                        {mail.status}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{mail.dueDate}</td>
                    <td className="">
                      <div className="flex flex-none items-center gap-x-4">
                        {/* View Message */}
                        {/* <a
                          href={mail.href}
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          View<span className="sr-only">, {mail.subject}</span>
                        </a> */}
                        <Popover>
                          <Popover.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            <EnvelopeIcon className="-ml-0.5 h-5 w-5 text-gray-700" aria-hidden="true" />
                            View
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-6 flex w-screen max-w-max -translate-x-1/2 px-4">
                              <div className="w-screen max-w-lg flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                <div className="relative rounded-lg p-4 hover:bg-gray-50">
                                  {/* <p className="font-semibold text-gray-900">
                                    {mail.subject}
                                    <span className="absolute inset-0" />
                                  </p> */}
                                <div className="sm:flex sm:items-baseline sm:justify-between">
                                  <p className="font-semibold text-gray-900">
                                    {mail.sender}
                                    {/* <span className="absolute inset-0" /> */}
                                  </p>
                                  <p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">
                                    <time dateTime={mail.dueDate}>{mail.dueDate}</time>
                                  </p>
                                  </div>
                                  <p className="mt-1 text-gray-600">{mail.message}</p>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </Popover>
                        
                        <Menu as="div" className="relative flex-none">
                          <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleChangeStatus({id:mail.Id, status:'Pentest'})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                    )}
                                  >
                                    Move To Pentest<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleChangeStatus({id:mail.Id, status:'Bounty'})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                    )}
                                  >
                                    Move To Bounty<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleChangeStatus({id:mail.Id, status:'Public Program'})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full w-full'
                                    )}
                                  >
                                    Move To Public Program<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleChangeStatus({id:mail.Id, status:'No Bounty'})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                    )}
                                  >
                                    Move To No Bounty<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleChangeStatus({id:mail.Id, status:'Unknown'})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                    )}
                                  >
                                    Move To Unknown<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                    )}
                                  >
                                    Delete<span className="sr-only">, {mail.subject}</span>
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
export default Mails;