import { gql } from '@apollo/client';

export const SEARCH_VULN = gql`
  query SEARCH_VULN($domain: String!) {
    domainReports(domain: $domain) {
      id
      findings {
        name
        severity
        matchedAt
      }
    }
  }
`;

export const GET_RANDOM_REPORT = gql`
  query GetRandomDomainReports {
    randomReport {
      id
      domain
      savedBy
      findings {
        name
        severity
        matchedAt
      }
    }
  }
`;

export const GET_DOMAIN = gql`
  query GetDomain {
    getDomain {
      id
      domain
      reportSent
      subdomains
    }
  }
`;

export const GET_TOP_HACKERS = gql`
  query GetTopHacker {
    topHackers {
      id
      nickname
      score
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport(
    $id: ID!
    $unfulfillingUserData: UnfulfillingUserDataInput
  ) {
    updateReport(
      id: $id
      unfulfillingUserData: $unfulfillingUserData
    ) {
      id
      unfulfilling
      unfulfillingUserData {
        userId
        reason
      }
    }
  }
`;

export const GET_MY_SUBMISSIONS = gql`
  query GetMySubmissions($status: String) {
    mySubmissions(status: $status) {
      id
      title
      severity
      submitted
      submitDate
    }
  }
`;

export const GET_ALL_SUBMISSIONS = gql`
  query GetAllSubmissions($submitted: Boolean, $status: Boolean) {
    allSubmissions(submitted: $submitted, status: $status) {
      id
      title
      severity
      comment
      status
      submitted
      by
      submitDate
    }
  }
`;

export const GET_SUBMISSION = gql`
  query GetSubmission($id: ID!) {
    submission(id: $id) {
      title
      severity
      description
      impact
      comment
      submitted
      by
      submitDate
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query GetCompanyContacts($id: ID!) {
    companyContacts(id: $id) {
      domain
      contacts {
        name
        jobTitle
        email
      }
    }
  }
`;

export const GET_MAILS = gql`
  query Mails {
    mails {
      Id
      msgId
      subject
      status
      sender
      senderEmail
      dueDate
      message
      companyWebsite
      companyName
    }
  }
`;

export const ALL_PROGRAMS = gql`
  query Programs {
    programs {
      Id
      name
      website
      completed
      recipients
      numFindings
      dealStatus
      dealType
      addedDate
    }
  }
`;

export const MY_PROGRAMS = gql`
  query MyPrograms {
    myPrograms {
      Id
      name
      website
      completed
      recipients
      numFindings
    }
  }
`;


export const GET_PROGRAM = gql`
  query Program($id: ID!) {
    program(id: $id) {
      program {
        Id
        name
        website
        completed
        recipients
        numFindings
        pendingReports
        dealType
        dealStatus
        addedDate
        comments {
          addedDate
          comment
          user
        }
      }
      pentesters {
        username
        nickname
      }
    }
  }
`;
