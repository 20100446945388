import jwt_decode from "jwt-decode";
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { useAuth } from '../../AuthContext';
import { useRedirect } from '../../RedirectContext';

import { LOGIN_USER } from '../../graphql/mutations';


function Login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [loginUser, { error }] = useMutation(LOGIN_USER);
  const { setUser, setToken, setName } = useAuth();

  const storedToken = localStorage.getItem('auth-token');

  useEffect(() => {
    if (storedToken) {
      const decoded = jwt_decode(storedToken);
      if (decoded.totp_verified) {
        navigate('/dashboard');
      } else {
        navigate('/verify-2fa');
      }
    }
  }, [storedToken, navigate]);

  const { redirectPath } = useRedirect();

  if (storedToken) {
    return null;
  }


  const onSubmit = async (values) => {
    try {
      const response = await loginUser({ variables: values });

      if (response.data.loginUser.success) {
        const token = response.data.loginUser.token;
        const decoded = jwt_decode(token);

        if (!decoded.totp_verified) {

          // Redirect to 2FA verification page
          localStorage.setItem('auth-token', token);
          setToken(token);
          setName(decoded.nickname);
          navigate('/verify-2fa');
        } else {
          setUser(decoded);
          setToken(token);
          localStorage.setItem('auth-token', token);
          navigate(redirectPath && redirectPath !== '/' ? redirectPath : '/dashboard');
        }
      } else {
        console.log(response.data.loginUser.message);
      }
    
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to BugSwagger portal
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[440px]">
        <div className="bg-white px-6 py-7 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  {...register("email")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...register("password")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-6">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

export default Login;