import {
  CheckIcon,
  HandThumbUpIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  PlusIcon,
  CalendarIcon,
  LinkIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid'

import { Fragment, useState, useEffect} from 'react'
import { useAuth } from '../../AuthContext';


import { Listbox, Menu, Transition } from '@headlessui/react'
import { useParams } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';

import { GET_PROGRAM } from '../../graphql/queries';
import { UPDATE_PROGRAM, ADD_COMMENT } from '../../graphql/mutations';
import LoadingIcon from '../layout/LoadingIcon';


const statuses = {
  Pentest: 'text-blue-700 bg-blue-50 ring-blue-600/20',
  Bounty: 'text-green-700 bg-green-50 ring-green-600/20',
  "Public Program": 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const publishingOptions = [
  { value: 1, current: false, name: 'Published', description: 'This program can be viewed by anyone who invited.'},
  { value: 0, current: false, name: 'Archive', description: 'This program will no longer be accessible.'},
]

function formatDate(addedDate, includeTime = false) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(addedDate);

  const dateString = date.toLocaleDateString(undefined, options);

  if (includeTime) {
      const timeString = date.toLocaleTimeString();
      const dateTimeString = `${dateString} ${timeString}`;
      return dateTimeString;
  } else {
      return dateString;
  }
}

function Program() {
  const { id } = useParams();
  const { user } = useAuth();

  const [comment, setComment] = useState("");
  const [selected, setSelected] = useState(publishingOptions[0]);
  
  const { loading, error, data } = useQuery(GET_PROGRAM, {
    variables: { id },
  });

  const [addComment, { data: commentData }] = useMutation(ADD_COMMENT);
  
  const [updateProgram, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_PROGRAM, {
    onCompleted: (data) => {
        if (data.updateProgram.success) {
          setSelected(data.updateProgram.dealStatus ? publishingOptions[0] : publishingOptions[1]);
        }
      },
  });

  useEffect(() => {
    if (data?.program.program) {
      const dealStatus = data?.program.program.dealStatus;
      setSelected(dealStatus ? publishingOptions[0] : publishingOptions[1]);
    }
  }, [data]);

  if (loading) return <LoadingIcon />;
  if (error) return <p>Error :(</p>;

  const add_comment = async (e) => {
    e.preventDefault();
    try {
      await addComment({
        variables: { id: data.program.program.Id, comment },
        refetchQueries: [{ query: GET_PROGRAM, variables: { id: data.program.program.Id } }]
      });
      setComment("");
    } catch (error) {
      console.error("Error adding comment", error);
      // Implement notification to the user about the error
    }
  };

  const handleDealStatus = async (option) => {
    if (option.current) return null;
    try {
      await updateProgram({
        variables: { id: data.program.program.Id, dealStatus: option.value },
      });
    } catch (error) {
      console.error("Error changing program status", error);
      // Implement notification to the user about the error
    }
  }

  const program = data?.program.program;
  const pentesters = data?.program.pentesters;
  
  
  const attachments = [
    { name: program.name+'_subdomains.txt', href: '#' },
  ]

  return (
    <>
      <main className="py-10">
        {/* Page header */}
        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            {/* <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full"
                  src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                  alt=""
                />
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div> */}
            <div>
              
              <a href={program.website} className="text-gray-900">
                <h1 className="text-2xl font-bold text-gray-900">{program.name} </h1>
              </a>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <time dateTime="2023-07-11">{formatDate(program.addedDate)}</time>
              </div>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <span 
              className={classNames(
                statuses[program.dealType],
                "inline-flex items-center rounded-full bg-bie-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20"
              )}
            >
              {program.dealType}
            </span>
            {/* <button
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {program.dealStatus ? "Live" : "Archived"}
            </button>
             */}
            <span className="ml-3 hidden sm:block">
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <LinkIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Copy Link
              </button>
            </span>

            <Listbox as="div" value={selected} className="sm:ml-3">
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                  <div className="relative">
                    <div className="inline-flex divide-x divide-indigo-600 rounded-md shadow-sm">
                      <div className="inline-flex divide-x divide-indigo-600 rounded-md shadow-sm">
                        <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-500 px-3 py-2 text-white shadow-sm">
                          <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          <p className="text-sm font-semibold">{selected.name}</p>
                        </div>
                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-500 p-2 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                          <span className="sr-only">Change published status</span>
                          <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </Listbox.Button>
                      </div>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute left-0 z-10 -mr-1 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                        {publishingOptions.map((option) => (
                          <Listbox.Option
                            key={option.name}
                            onClick={() => handleDealStatus(option)}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-indigo-500 text-white' : 'text-gray-900',
                                'cursor-default select-none p-4 text-sm'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p className={selected ? 'font-semibold' : 'font-normal'}>{option.name}</p>
                                  {selected ? (
                                    <span className={active ? 'text-white' : 'text-indigo-500'}>
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </div>
                                <p className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}>
                                  {option.description}
                                </p>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                    Program Information
                  </h2>
                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Total Reposts</dt>
                      <dd className="mt-1 text-sm text-gray-900">{program.numFindings+program.pendingReports?.length}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Accepted reports</dt>
                      <dd className="mt-1 text-sm text-gray-900">{program.numFindings}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Pending reports</dt>
                      <dd className="mt-1 text-sm text-gray-900">{program.pendingReports?.length}</dd>
                    </div>
                    {/* <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Salary expectation</dt>
                      <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Phone</dt>
                      <dd className="mt-1 text-sm text-gray-900">+1 555-555-5555</dd>
                    </div> */}
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Findings by</dt>
                      <ul role="list" className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-4">
                        {program.completed?.map((user, userIdx) => (
                          <li key={userIdx}>
                            <button
                              type="button"
                              className="group flex items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-center shadow-sm hover:bg-gray-50"
                            >
                              <span className="flex min-w-0 flex-1 items-center space-x-3">
                                {/* <span className="block flex-shrink-0">
                                  <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                </span> */}
                                <span className="block min-w-0 flex-1">
                                  <span className="block truncate text-sm font-medium text-gray-900">{user}</span>
                                  {/* <span className="block truncate text-sm font-medium text-gray-500">{user.role}</span> */}
                                </span>
                              </span>
                              {/* <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                <PlusIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                              </span> */}
                            </button>
                          </li>
                        ))}
                      </ul>
                      {/* <dd className="mt-1 text-sm text-gray-900">
                        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
                        Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia
                        proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                      </dd> */}
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                          {attachments.map((attachment) => (
                            <li
                              key={attachment.name}
                              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                            >
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <span className="ml-2 w-0 flex-1 truncate">{attachment.name}</span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a href={attachment.href} className="font-medium text-blue-600 hover:text-blue-500">
                                  Download
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <a
                    href="#"
                    className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
                  />
                </div>
              </div>
            </section>

            {/* Comments*/}
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Notes
                    </h2>
                  </div>
                  <div className="px-4 py-6 sm:px-6">
                    <ul role="list" className="space-y-8">
                      {data?.program?.program?.comments.map((comment) => (
                        <li key={comment.addedDate}>
                          <div className="flex space-x-3">
                            {/* <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
                                alt=""
                              />
                            </div> */}
                            <div>
                              <div className="text-sm">
                                <a href="#" className="font-medium text-gray-900">
                                  {comment.user}
                                </a>
                              </div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>{comment.comment}</p>
                              </div>
                              <div className="mt-2 space-x-2 text-sm">
                                <span className="font-medium text-gray-500">{formatDate(comment.addedDate, true)}</span>{' '}
                                <span className="font-medium text-gray-500">&middot;</span>{' '}
                                <button type="button" className="font-medium text-gray-900">
                                  Reply
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                  <div className="flex space-x-3">
                    {/* <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div> */}
                    <div className="min-w-0 flex-1">
                      {/* <form action="#"> */}
                      <div>
                        <label htmlFor="comment" className="sr-only">
                          About
                        </label>
                        <textarea
                          id="comment"
                          name="comment"
                          rows={3}
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Add a note"
                          // defaultValue={''}
                          value={comment}
                          onChange={e => setComment(e.target.value)}
                        />
                      </div>
                      <div className="mt-3 flex items-center justify-between">
                        <a
                          href="#"
                          className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900"
                        >
                          <QuestionMarkCircleIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span></span>
                        </a>
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={add_comment}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   addCommentMutation({ variables: { id: program.Id, comment: comment } });
                          // }}
                        >
                          Comment
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          
          { user && user.isSuperUser && (
          <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">

            {/* Activity Feed */}
            {/* <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                Timeline
              </h2>

              <div className="mt-6 flow-root">
                <ul role="list" className="-mb-8">
                  {timeline.map((item, itemIdx) => (
                    <li key={item.id}>
                      <div className="relative pb-8">
                        {itemIdx !== timeline.length - 1 ? (
                          <span
                            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                item.type.bgColorClass,
                                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                              )}
                            >
                              <item.type.icon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                              <p className="text-sm text-gray-500">
                                {item.content}{' '}
                                <a href="#" className="font-medium text-gray-900">
                                  {item.target}
                                </a>
                              </p>
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              <time dateTime={item.datetime}>{item.date}</time>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}

            {/* Add Members */}
            {pentesters && 
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <div>
                <div className="text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Add team to program</h2>
                  <p className="mt-1 text-sm text-gray-500">
                    You haven’t added any team members to your program yet.
                  </p>
                </div>
                <form className="mt-6 flex">
                  <label htmlFor="email" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter an email"
                  />
                  <button
                    type="submit"
                    className="ml-4 flex-shrink-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send invite
                  </button>
                </form>
              </div>
              <div className="mt-10">
                { pentesters ? (
                  <h3 className="text-sm font-medium text-gray-500">All added to the program</h3>
                  ) : (
                  <h3 className="text-sm font-medium text-gray-500">Pentesters previously added to programs</h3>
                  )
                }
                <ul role="list" className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                  {pentesters?.map((user, userIdx) => (
                    <li key={userIdx} className="flex items-center justify-between space-x-3 py-4">
                      <div className="flex min-w-0 flex-1 items-center space-x-3">
                        {/* <div className="flex-shrink-0">
                          <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                        </div> */}
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900">{user.nickname}</p>
                          {/* <p className="truncate text-sm font-medium text-gray-500">{user.role}</p> */}
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={add_comment}
                          className="inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-gray-900"
                        >
                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Invite <span className="sr-only">{user.nickname}</span>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            }

          </section>
          )}
        </div>
      </main>
    </>
  )
};

export default Program;