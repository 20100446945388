import { React } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TOP_HACKERS } from '../../graphql/queries';
import { useAuth } from '../../AuthContext';
import LoadingIcon from '../layout/LoadingIcon';
import { Link } from 'react-router-dom';

import {
  CheckCircleIcon,
  DocumentChartBarIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline'

const cards = [
  { name: 'Total submissions', href: '/all-submissions', icon: DocumentChartBarIcon, amount: '45' },
  { name: 'Accepted submissions', href: '/all-submissions?filter=accepted', icon: CheckCircleIcon, amount: '30' },
  { name: 'Pending submissions', href: '/all-submissions?filter=pending', icon: ArrowPathIcon, amount: '15' },
]


function Dashboard() {
  const { user } = useAuth();
  const { loading, error, data } = useQuery(GET_TOP_HACKERS);

  if (loading) return <LoadingIcon />;
  if (error) return <p>Error: {error.message}</p>;

  const topHackersData = data.topHackers;    

  // { user && user.isSuperUser && (
  //   <div className="sm:flex sm:items-center">
  //     <div className="sm:flex-auto">
  //       <h1 className="text-base font-semibold leading-6 text-gray-900">Hackers</h1>
  //       {/* <p className="mt-2 text-sm text-gray-700">
  //       A list of all the users in your account including their name, title, email and role.
  //     </p> */}
  //     </div>
  //     <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
  //       <Link
  //         to={'/add-user'}
  //         type="button"
  //         className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //         >
  //         Add user
  //       </Link>
  //     </div>
  //   </div>
  //   )}
  return (
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">

        {/* overview */}

        { user && user.isSuperUser && (
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {cards.map((card) => (
              <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="truncate text-sm font-medium text-gray-500">{card.name}</dt>
                        <dd>
                          <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-5 py-3">
                  <div className="text-sm">
                    <Link to={card.href} type="button" className="font-medium text-cyan-700 hover:text-cyan-900">
                      View all
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">

          {/* top hackers */}
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Top hackers
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {topHackersData.map((user) => (
                    <tr key={user.nickname}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {user.nickname}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* my cridets */}
          
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Credits
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Total Reports
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.submissions}</td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Score
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.score}</td>
                  </tr>
                  {/* <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Pending Reports
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.score}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;